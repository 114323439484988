import axios from 'axios';
import config from '../config';
import { TempZone, Zone } from '../interfaces';

export const createZone = (zone: Zone | TempZone, name: string) => {
    const url = `${config.API_URL}/zones`;

    const { circleAreaGeometry, polygonAreaGeometry } = zone;

    const data: any = {
        name,
        circleAreaGeometry,
        polygonAreaGeometry,
    };

    return axios.post(url, data).then((response: any) => {
        return response.data.data;
    });
};
