import React, { useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress, IconButton, Tooltip } from '@mui/material';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { useEffect } from 'react';
import { getOnboardDeviceSecrets } from '../api/getOnboardDeviceSecrets';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import { DeviceSecrets, ToastType } from '../interfaces';
import { Close as CloseIcon } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';

interface Props {
    open: boolean;
    selectedDeviceId: string;
    onClose: () => void;
}

export const GetSecretsDialog = ({ open, onClose, selectedDeviceId }: Props) => {
    const [secrets, setSecrets] = useState<DeviceSecrets | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const hiddenCell = (cellValues: GridCellParams) => {
        const [title, setTitle] = useState<string>('Скопіювати');

        const onCopy = () => {
            copyTextToClipboard(cellValues.value as string);
            setTitle('Скопійовано');
        };

        return (
            <div>
                <span>{cellValues.value ? '****' : ''}</span>

                <Tooltip title={title} placement="top">
                    <IconButton
                        onMouseLeave={() => {
                            setTitle('Скопіювати');
                        }}
                        onClick={onCopy}
                        size={'small'}
                        sx={{ marginLeft: 1 }}
                        aria-label="copy"
                    >
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'deviceId' },
            { key: 'userPassword', renderCell: hiddenCell },
            { key: 'accessPointPassword', renderCell: hiddenCell },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                width: 180,
                sortable: false,
                filterable: false,
            };
        });
    }, []);

    const rows = useMemo(() => {
        if (selectedDeviceId && secrets) {
            return [
                {
                    deviceId: selectedDeviceId,
                    ...secrets,
                    id: selectedDeviceId,
                },
            ];
        }
    }, [selectedDeviceId, secrets]);

    useEffect(() => {
        (async () => {
            if (open && selectedDeviceId) {
                try {
                    setIsLoading(true);
                    const secrets = await getOnboardDeviceSecrets(selectedDeviceId);
                    setSecrets(secrets);
                } catch (e: any) {
                    if (e.response.status === 404) {
                        setToastMessage({
                            message: `Не знайдено секретів для пристроя з айді ${selectedDeviceId}`,
                            type: ToastType.WARNING,
                        });
                    } else {
                        setToastMessage({
                            message: `Сталася помилка при отриманні секретів для пристроя з айді ${selectedDeviceId}`,
                            type: ToastType.WARNING,
                        });
                    }
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [open, selectedDeviceId]);

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    Secrets
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
                </DialogTitle>
                <DialogContent sx={{ width: 600, minHeight: 135 }}>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '25px 0' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            {rows && (
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    disableRowSelectionOnClick
                                    hideFooter
                                />
                            )}
                        </>
                    )}
                </DialogContent>
            </Dialog>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
