import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import zonesReducer from './zones.reducer';
import devicesReducer from './devices.reducer';
import userReducer from './user.reducer';
import organizationsReducer from './organizations.reducer';

export const store = configureStore({
    reducer: {
        zones: zonesReducer,
        devices: devicesReducer,
        user: userReducer,
        organizations: organizationsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
