import axios from 'axios';
import config from '../config';

export const attachOnboardDeviceOrganization = (deviceId: string, organizationName: string) => {
    return axios
        .put(`${config.API_URL}/onboard-devices/${deviceId}/organization`, { organizationName })
        .then((response: any) => {
            return response.data.message;
        });
};
