import styled from 'styled-components';
import { forwardRef } from 'react';

const BadgeItem = styled.span`
    padding: 3px 7px;
    color: white;
    border-radius: 12px;
    display: inline-flex;
`;

export const Badge = forwardRef((props: any, ref: any) => {
    const backgroundColor = props.backgroundColor;

    props = { ...props, backgroundColor: undefined };

    return <BadgeItem style={{ backgroundColor: backgroundColor }} {...props} ref={ref} />;
});
