import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { UserRoles } from '../interfaces';
import { hasAllowedRole } from '../utils/hasAllowedRole';

interface ProtectedRouteProps {
    allowedRoles: UserRoles[];
    children: ReactElement<any, any>;
}

export const ProtectedRoute = ({ allowedRoles, children }: ProtectedRouteProps) => {
    const { user, loading, userRoles } = useCognitoUser();

    if (typeof loading !== 'boolean' || loading) {
        // Render some loading state while we check for the current user
        return <></>;
    }

    if (!user || !userRoles || !hasAllowedRole(userRoles, allowedRoles)) {
        return <Navigate to="/login" replace />;
    }

    return children;
};
