import { polygon } from '@turf/turf';

const bbox = { left: 21.482788, right: 44.405816, bottom: 43.665126, top: 54.645179 };

const coordinates = [
    [bbox.left, bbox.bottom], // Bottom-left
    [bbox.left, bbox.top], // Top-left
    [bbox.right, bbox.top], // Top-right
    [bbox.right, bbox.bottom], // Bottom-right
    [bbox.left, bbox.bottom], // Close the polygon by returning to Bottom-left
];

const polygonFeature = polygon([coordinates]);

export const useGetAvailableAreaPolygon = () => {
    return polygonFeature;
};
