import {
    loadZones,
    selectNewZone,
    selectNewZoneActiveTab,
    selectNewZoneRadius,
    setNewZone,
    setNewZoneActiveTab,
    setNewZoneRadius,
    setNewZoneSidebarOpened,
} from '../store/zones.reducer';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, IconButton, Tab, Tabs } from '@mui/material';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography/Typography';
import Slider from '@mui/material/Slider/Slider';
import { AreaGeometryType, CircleShape, LatLng, TempZone, ToastType } from '../interfaces';
import { createZone } from '../api/createZone';
import { area as TurfArea, circle, point } from '@turf/turf';
import polygonDifference from '@turf/difference';
import { useGetAvailableAreaPolygon } from '../hooks/useGetAvailableAreaPolygon';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { polygon as TurfPolygon } from '@turf/helpers';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from './ToastWrapper';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const tabTypeToNumber = (areaType: AreaGeometryType): number => {
    switch (areaType) {
        case AreaGeometryType.Circle:
            return 0;
        case AreaGeometryType.Polygon:
            return 1;
        default:
            return 0;
    }
};

const tabNumberToType = (tab: number): AreaGeometryType => {
    switch (tab) {
        case 0:
            return AreaGeometryType.Circle;
        case 1:
            return AreaGeometryType.Polygon;
        default:
            return AreaGeometryType.Circle;
    }
};

export const NewZone = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const polygonFeature = useGetAvailableAreaPolygon();
    const dispatch = useAppDispatch();
    const newZone = useAppSelector(selectNewZone) as TempZone;
    const activeTabType = useAppSelector(selectNewZoneActiveTab);
    const newZoneRadius = useAppSelector(selectNewZoneRadius);
    const [name, setName] = useState<string>('');
    const [sliderValue, setRadiusSliderValue] = useState<number>(newZoneRadius);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const tabType = tabNumberToType(newValue);
        dispatch(setNewZone(null));
        dispatch(setNewZoneActiveTab(tabType));
    };

    const getTurfPolygon = (coordinates: LatLng[]) => {
        coordinates = [...coordinates];

        if (coordinates.length < 3) {
            for (let i = 1; i < 3; i++) {
                if (!coordinates[i]) {
                    coordinates[i] = coordinates[i - 1];
                }
            }
        }

        coordinates.push(coordinates[0]);

        return TurfPolygon([[...coordinates.map((c) => [c.lng, c.lat])]]);
    };

    const polygonArea = useMemo(() => {
        if (newZone && newZone.polygonAreaGeometry && newZone.polygonAreaGeometry.coordinates.length > 0) {
            const coordinates = newZone.polygonAreaGeometry.coordinates.slice();

            const area = TurfArea(getTurfPolygon(coordinates));

            return Math.round(area / 1000000);
        }
    }, [newZone]);

    const onRadiusChange = (_event: Event, value: number | number[]) => {
        const radius = value as number;
        dispatch(setNewZoneRadius(radius));
        setRadiusSliderValue(radius);
        if (newZone) {
            const circleAreaGeometry = newZone.circleAreaGeometry as CircleShape;

            dispatch(
                setNewZone({
                    circleAreaGeometry: {
                        ...circleAreaGeometry,
                        radius,
                    },
                }),
            );
        }
    };

    const generateTiles = async () => {
        if (newZone) {
            setLoading(true);
            try {
                await createZone(newZone, name);

                dispatch(loadZones());
                dispatch(setNewZone(null));
                dispatch(setNewZoneSidebarOpened(false));
            } catch (e) {
                console.error('Create zone failed', e);
                setToastMessage({
                    message: `При створенні зони відбулася помилка`,
                    type: ToastType.WARNING,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const isTargetInAvailableArea = useMemo(() => {
        let result = false;
        let areaPolygon;

        if (!newZone) {
            return false;
        }

        if (newZone.circleAreaGeometry) {
            areaPolygon = circle(
                point([newZone.circleAreaGeometry.center.lng, newZone.circleAreaGeometry.center.lat]),
                newZone.circleAreaGeometry.radius,
                {
                    units: 'kilometers',
                },
            );
        } else if (newZone.polygonAreaGeometry) {
            areaPolygon = getTurfPolygon(newZone.polygonAreaGeometry.coordinates);
        }

        if (!areaPolygon) {
            return false;
        }

        const areaDifference = polygonDifference(areaPolygon, polygonFeature);
        result = areaDifference === null;

        return result;
    }, [newZone, polygonFeature]);

    const activeTab = useMemo(() => {
        return tabTypeToNumber(activeTabType);
    }, [activeTabType]);

    const clearPolygon = () => {
        dispatch(setNewZone(null));
    };

    return (
        <>
            <DrawerHeader>
                <IconButton
                    onClick={() => {
                        dispatch(setNewZone(null));
                        dispatch(setNewZoneSidebarOpened(false));
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <div className="flex-grow max-w-[300px] p-4 relative">
                <TextField
                    required={true}
                    size="small"
                    label="Назва нової зони"
                    variant="outlined"
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value);
                    }}
                />
                <div className="pt-3" />

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Круг" {...a11yProps(0)} />
                        <Tab label="Полігон" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                {activeTab === 0 && (
                    <div className="pt-4">
                        <Typography gutterBottom>Радіус (км.)</Typography>
                        <Slider
                            disabled={loading}
                            max={50}
                            value={sliderValue}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            onChange={onRadiusChange}
                        />
                    </div>
                )}
                {activeTab === 1 && (
                    <div className="pt-4">
                        <Typography gutterBottom>Площа {polygonArea || 0} (км.)</Typography>

                        <div className="mt-2">
                            <Button onClick={clearPolygon} variant="outlined">
                                Очистити зону
                            </Button>
                        </div>
                    </div>
                )}
                <div className={'mt-2 inline-flex'}>
                    <Button
                        disabled={!newZone || !isTargetInAvailableArea || loading || !name}
                        onClick={generateTiles}
                        variant="outlined"
                    >
                        Згенерувати тайли
                        {loading && (
                            <span className="ml-2 inline-flex">
                                <CircularProgress size={15} />
                            </span>
                        )}
                    </Button>
                </div>
            </div>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
