import React from 'react';
import { useField, useFormikContext } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const FormikAutocomplete = ({ name, options, ...otherProps }: any) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    return (
        <Autocomplete
            {...field}
            {...otherProps}
            options={options}
            onChange={(event, value) => {
                setFieldValue(name, value);
            }}
            value={field.value || null} // Ensure value is never undefined
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    label={otherProps.label}
                />
            )}
        />
    );
};
