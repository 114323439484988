import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserRoles } from '../interfaces';

export const getUserRoles = (currentUser: CognitoUser): Promise<UserRoles[]> => {
    return new Promise((resolve, reject) => {
        currentUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
                reject('Error getting cognito session');
            } else if (session) {
                const token = session.getIdToken();
                resolve(token.decodePayload()['cognito:groups']);
            }
        });
    });
};
