import * as React from 'react';
import { useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Zones } from '../routes/Zones';
import Button from '@mui/material/Button';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Devices } from '../routes/Devices';
import { SystemConfigurations } from '../routes/SystemConfigurations';
import { ProtectedRoute } from './ProtectedRoute';
import { UserRoles } from '../interfaces';
import { hasAllowedRole } from '../utils/hasAllowedRole';
import { Organizations } from '../routes/Organizations';

export default function Layout() {
    const { logout, userRoles, user } = useCognitoUser();

    const navigate = useNavigate();

    const pages = useMemo(() => {
        if (userRoles && hasAllowedRole(userRoles, [UserRoles.ADMINS])) {
            return ['zones', 'devices', 'configurations', 'organizations'];
        } else if (userRoles && hasAllowedRole(userRoles, [UserRoles.FARM])) {
            return ['devices'];
        }

        return [];
    }, userRoles);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: 1300,
                }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        {pages.map((page) => (
                            <Button
                                onClick={() => {
                                    navigate(page);
                                }}
                                key={page}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Button
                        onClick={() => {
                            logout();
                        }}
                        color="inherit"
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            {user && userRoles ? (
                <Routes>
                    <Route
                        path="zones"
                        element={
                            <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                <Zones />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="devices"
                        element={
                            <ProtectedRoute allowedRoles={[UserRoles.ADMINS, UserRoles.FARM]}>
                                <Devices />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="configurations"
                        element={
                            <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                <SystemConfigurations />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="organizations"
                        element={
                            <ProtectedRoute allowedRoles={[UserRoles.ADMINS]}>
                                <Organizations />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="devices" replace />} />
                </Routes>
            ) : (
                <></>
            )}
        </>
    );
}
