import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadSystemConfigurations, selectSystemConfigurations } from '../store/devices.reducer';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { getContainerVersions } from '../api/getContainerVersions';
import { getApplicationsVersions } from '../api/getApplicationsVersions';
import { getProcessedEvents } from '../api/getProcessedEvents';
import { ToastType } from '../interfaces';
import { createSystemConfiguration } from '../api/createSystemConfiguration';
import { FormikAutocomplete } from './FormikAutocomplete';

interface SystemConfigurationForm {
    autocomplete?: any;
    systemVersion?: string;
    containerVersion?: string;
    applicationVersion?: string;
    mapVersion?: string;
}

export const AddSystemConfigurationDialog = ({ open, onClose }: any) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const [containerVersions, setContainerVersions] = useState<string[] | null>(null);
    const [applicationVersions, setApplicationVersions] = useState<string[] | null>(null);
    const [processedEvents, setProcessedEvents] = useState<string[] | null>(null);
    const systemConfigurations = useAppSelector(selectSystemConfigurations);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const initialValues: SystemConfigurationForm = {
        systemVersion: '',
        containerVersion: '',
        applicationVersion: '',
        mapVersion: '',
    };

    const validateForm = (values: any) => {
        const errors: SystemConfigurationForm = {};
        if (!values.systemVersion) {
            errors.systemVersion = 'Required';
        } else if (systemConfigurations?.find((c) => c.systemVersion === values.systemVersion)) {
            errors.systemVersion = 'Конфігурація з такою назвою вже існує';
        }

        if (!values.containerVersion) {
            errors.containerVersion = 'Required';
        }
        if (!values.applicationVersion) {
            errors.applicationVersion = 'Required';
        }
        if (!values.mapVersion) {
            errors.mapVersion = 'Required';
        }
        return errors;
    };

    const handleSubmit = async (values: SystemConfigurationForm, { setSubmitting }: any) => {
        try {
            await createSystemConfiguration(values);
            dispatch(loadSystemConfigurations());
        } catch (error) {
            setToastMessage({
                message: `Під час створенні конфігурації відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            onClose();
        }
        setSubmitting(false);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            await Promise.all([
                getContainerVersions()
                    .then((containerVersions) => setContainerVersions(containerVersions.reverse()))
                    .catch((e) => {
                        console.error(e);
                        setToastMessage({
                            message: `При отриманні версій контейнера відбулася помилка`,
                            type: ToastType.WARNING,
                        });
                    }),
                getApplicationsVersions()
                    .then((applicationVersions) => setApplicationVersions(applicationVersions.reverse()))
                    .catch((e) => {
                        console.error(e);
                        setToastMessage({
                            message: `При отриманні версій апплікації відбулася помилка`,
                            type: ToastType.WARNING,
                        });
                    }),
                getProcessedEvents()
                    .then((processedEvents) => setProcessedEvents(processedEvents.reverse()))
                    .catch((e) => {
                        console.error(e);
                        setToastMessage({
                            message: `При отриманні списку доступних версій карти відбулася помилка`,
                            type: ToastType.WARNING,
                        });
                    }),
            ]);

            setLoading(false);
        })();
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add System Configuration</DialogTitle>
            <DialogContent sx={{ width: 350, minHeight: 307 }}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '110px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                        {({ errors, touched, isSubmitting }) => (
                            <Form>
                                <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                        <Field
                                            as={TextField}
                                            name="systemVersion"
                                            label="System Version Name *"
                                            variant="outlined"
                                            size="small"
                                            error={touched.systemVersion && !!errors.systemVersion}
                                            helperText={touched.systemVersion && errors.systemVersion}
                                        />
                                    </FormControl>

                                    {containerVersions && (
                                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                            <Field
                                                as={FormikAutocomplete}
                                                size="small"
                                                name="containerVersion"
                                                label="Container Version *"
                                                options={containerVersions}
                                                error={touched.containerVersion && !!errors.containerVersion}
                                            />
                                        </FormControl>
                                    )}

                                    {applicationVersions && (
                                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                            <Field
                                                as={FormikAutocomplete}
                                                size="small"
                                                name="applicationVersion"
                                                label="Application Version *"
                                                options={applicationVersions}
                                                error={
                                                    touched.applicationVersion && !!errors.applicationVersion
                                                }
                                            />
                                        </FormControl>
                                    )}

                                    {processedEvents && (
                                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                            <Field
                                                as={FormikAutocomplete}
                                                size="small"
                                                name="mapVersion"
                                                label="Map Version *"
                                                options={processedEvents}
                                                error={touched.mapVersion && !!errors.mapVersion}
                                            />
                                        </FormControl>
                                    )}

                                    <DialogActions>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={onClose}
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            size="small"
                                            variant="outlined"
                                            disabled={isSubmitting}
                                            color="primary"
                                        >
                                            Add {isSubmitting && <CircularProgress size={15} />}
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </DialogContent>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Dialog>
    );
};
