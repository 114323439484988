import axios from 'axios';
import config from '../config';
import { objectIdToString } from '../utils/objectIdToString';
import { ObjectId } from '../interfaces';

export const deleteZone = (zoneId: ObjectId): Promise<any> => {
    const url = `${config.API_URL}/zones/${objectIdToString(zoneId)}`;

    return axios.delete(url).then((response: any) => {
        return response.data.data;
    });
};
