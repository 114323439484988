import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';
import { ActivationStick, Device, SystemConfiguration } from '../interfaces';
import { getOnboardDevices } from '../api/getOnboardDevices';
import { getActivationSticks } from '../api/getActivationSticks';
import { getSystemConfigurations } from '../api/getSystemConfigurations';

export interface DevicesState {
    onboardDevices: Device[] | null;
    selectedOnboardDeviceTableRows: string[];
    activationSticks: ActivationStick[] | null;
    systemConfigurations: SystemConfiguration[] | null;
}

const initialState: DevicesState = {
    selectedOnboardDeviceTableRows: [],
    onboardDevices: null,
    activationSticks: null,
    systemConfigurations: null,
};

export const loadOnboardDevices = createAsyncThunk('devices/loadOnboardDevices', async () => {
    return await getOnboardDevices();
});

export const loadActivationSticks = createAsyncThunk('devices/loadActivationSticks', async () => {
    return await getActivationSticks();
});

export const loadSystemConfigurations = createAsyncThunk('devices/loadSystemConfigurations', async () => {
    return await getSystemConfigurations();
});

export const DevicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        setSelectedOnboardDeviceTableRows: (state, action: PayloadAction<string[]>) => {
            state.selectedOnboardDeviceTableRows = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadOnboardDevices.fulfilled, (state, action) => {
                state.onboardDevices = action.payload;
            })
            .addCase(loadOnboardDevices.rejected, (state, action) => {
                console.error(action.error);
            })
            .addCase(loadActivationSticks.fulfilled, (state, action) => {
                state.activationSticks = action.payload;
            })
            .addCase(loadActivationSticks.rejected, (state, action) => {
                console.error(action.error);
            })
            .addCase(loadSystemConfigurations.fulfilled, (state, action) => {
                state.systemConfigurations = action.payload;
            })
            .addCase(loadSystemConfigurations.rejected, (state, action) => {
                console.error(action.error);
            });
    },
});

export const { setSelectedOnboardDeviceTableRows } = DevicesSlice.actions;

export const selectOnboardDevices = (state: RootState) => state.devices.onboardDevices;
export const selectSystemConfigurations = (state: RootState) => state.devices.systemConfigurations;
export const selectActivationSticks = (state: RootState) => state.devices.activationSticks;
export const selectSelectedOnboardDeviceTableRows = (state: RootState) =>
    state.devices.selectedOnboardDeviceTableRows;

export default DevicesSlice.reducer;
