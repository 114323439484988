import axios from 'axios';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import config from '../config';

const userPool = new CognitoUserPool(config.POOL_DATA);

axios.interceptors.request.use(
    async (axiosConfig) => {
        if (axiosConfig.url?.indexOf(config.API_URL) !== -1) {
            const token = await getToken();
            if (token) {
                axiosConfig.headers.Authorization = token as string;
            }
        }

        return axiosConfig;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        // Reject promise if usual error
        if (!error.response || error.response.status !== 401) {
            return Promise.reject(error);
        }

        // Attempt to refresh token if a 401 response
        return refreshToken()
            .then((newToken) => {
                // New request with new token
                const config = error.config;
                config.headers['Authorization'] = newToken;
                return axios(config);
            })
            .catch((refreshError) => {
                console.error(refreshError);
                return Promise.reject(error);
            });
    },
);

const getToken = () => {
    return new Promise((resolve) => {
        const cognitoUser = userPool.getCurrentUser(); // assuming the user is currently logged in

        if (cognitoUser) {
            cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    resolve(null);
                } else if (session) {
                    const token = session.getIdToken().getJwtToken();
                    resolve(token);
                }
            });
        } else {
            resolve(null);
        }
    });
};

const refreshToken = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser(); // assuming the user is currently logged in

        if (cognitoUser) {
            cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
                if (err) {
                    reject(err);
                    return;
                }

                if (!session) {
                    reject('No Cognito session to refresh the token.');
                }

                const refreshTokenObj = session!.getRefreshToken();
                cognitoUser.refreshSession(refreshTokenObj, (refreshErr, newSession) => {
                    if (refreshErr) {
                        // redirect to login page
                        cognitoUser.signOut();
                        window.location.href = '/login';
                        window.location.reload();
                        reject(refreshErr);
                        return;
                    }
                    resolve(newSession.getIdToken().getJwtToken());
                });
            });
        } else {
            reject('User is not logged in');
        }
    });
};
