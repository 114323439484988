import { useState } from 'react';

import { IToast } from '../interfaces';

export const useToastMessage = () => {
    const [toastMessage, setToastMessage] = useState<IToast | null>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const handleCloseToast = () => {
        setIsOpened(false);
    };

    const messageSetter = (message: IToast | null) => {
        setToastMessage(message);
        setIsOpened(true);
    };

    return {
        isOpened,
        toastMessage,
        setToastMessage: messageSetter,
        handleCloseToast,
    };
};
