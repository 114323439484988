import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';
import { AreaGeometryType, TempZone, Zone, ZoneStatus } from '../interfaces';
import { getZones } from '../api/getZones';

export interface ZonesState {
    newZoneActiveTab: AreaGeometryType;
    newZone: TempZone | null;
    newZoneSidebarOpened: boolean;
    newZoneCreating: boolean;
    newZoneRadius: number;
    selectedZone: Zone | null;
    zones: Zone[] | null;
}

const initialState: ZonesState = {
    newZoneActiveTab: AreaGeometryType.Polygon,
    newZone: null,
    newZoneSidebarOpened: false,
    newZoneCreating: false,
    newZoneRadius: 25,
    selectedZone: null,
    zones: null,
};

export const loadZones = createAsyncThunk('zones/loadZones', async () => {
    const { zones } = await getZones();
    return zones;
});

export const ZonesSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        setNewZone: (state, action: PayloadAction<TempZone | null>) => {
            state.newZone = action.payload;
            state.selectedZone = null;
        },
        setNewZoneActiveTab: (state, action: PayloadAction<AreaGeometryType>) => {
            state.newZoneActiveTab = action.payload;
        },
        setNewZoneSidebarOpened: (state, action: PayloadAction<boolean>) => {
            state.newZoneSidebarOpened = action.payload;
        },
        setNewZoneRadius: (state, action: PayloadAction<number>) => {
            state.newZoneRadius = action.payload;
        },
        setSelectedZone: (state, action: PayloadAction<Zone | null>) => {
            state.selectedZone = action.payload;
            state.newZone = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadZones.fulfilled, (state, action) => {
                state.zones = action.payload;
                state.newZoneCreating = !!action.payload.find((z) => z.status === ZoneStatus.PROCESSING);
            })
            .addCase(loadZones.rejected, (state, action) => {
                console.error(action.error);
            });
    },
});

export const { setNewZone, setSelectedZone, setNewZoneRadius, setNewZoneActiveTab, setNewZoneSidebarOpened } =
    ZonesSlice.actions;

export const selectNewZone = (state: RootState) => state.zones.newZone;
export const selectNewZoneActiveTab = (state: RootState) => state.zones.newZoneActiveTab;
export const selectNewZoneRadius = (state: RootState) => state.zones.newZoneRadius;
export const selectSelectedZone = (state: RootState) => state.zones.selectedZone;
export const selectZones = (state: RootState) => state.zones.zones;
export const selectNewZoneSidebarOpened = (state: RootState) => state.zones.newZoneSidebarOpened;
export const selectNewZoneCreating = (state: RootState) => state.zones.newZoneCreating;

export default ZonesSlice.reducer;
