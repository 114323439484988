import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useCognitoUser } from '../hooks/useCognitoUser';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { ToastType } from '../interfaces';

export const Login = () => {
    const { logIn, loading } = useCognitoUser();
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get('email');
        const password = data.get('password');

        if (email && password) {
            try {
                await logIn(email as string, password as string);
            } catch (e: any) {
                console.log('Login error', e);

                if (e.code === 'NotAuthorizedException') {
                    setToastMessage({
                        message: 'Невірна пошта або пароль',
                        type: ToastType.WARNING,
                    });
                } else if (e.code === 'UserNotFoundException') {
                    setToastMessage({
                        message: `Невірна пошта`,
                        type: ToastType.WARNING,
                    });
                } else {
                    setToastMessage({
                        message: `Щось пішло не так. зв'яжіться з підтримкою`,
                        type: ToastType.WARNING,
                    });
                }
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Вхід
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Вхід
                    </Button>
                </Box>
            </Box>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Container>
    );
};
