import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#778d31',
        },
        secondary: {
            main: '#f9a54b',
        },
    },
};

export const theme = createTheme(themeOptions);
