import { useAppSelector } from '../store/hooks';
import { selectNewZoneSidebarOpened } from '../store/zones.reducer';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { ZonesHistory } from '../Components/ZonesHistory';
import { NewZone } from '../Components/NewZone';
import { MapPicker } from '../Components/MapPicker';
import * as React from 'react';

const drawerWidth = 280;

export const Zones = () => {
    const newZoneSidebarOpened = useAppSelector(selectNewZoneSidebarOpened);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        paddingTop: '68px',
                        boxSizing: 'border-box',
                    },
                    '.MuiPaper-root': {
                        boxShadow:
                            '0px 8px 10px -5px rgba(0,0,0,0.15), 0px 16px 24px 2px rgba(0,0,0,0.1), 0px 6px 30px 5px rgba(0,0,0,0.08)',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <ZonesHistory />
            </Drawer>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        marginTop: '68px',
                    },
                    '& .MuiModal-backdrop': {
                        display: 'none',
                    },
                    '.MuiPaper-root': {
                        boxShadow:
                            '0px 8px 10px -5px rgba(0,0,0,0.1), 0px 16px 24px 2px rgba(0,0,0,0.05), 0px 6px 30px 5px rgba(0,0,0,0.0)',
                    },
                }}
                anchor="left"
                open={newZoneSidebarOpened}
            >
                <NewZone />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
                <MapPicker />
            </Box>
        </Box>
    );
};
