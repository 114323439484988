import { ZoneStatus } from '../interfaces';

export const StatusColor: any = {
    [ZoneStatus.CREATED]: '#f9c84b',
    [ZoneStatus.PROCESSING]: '#f9a54b',
    [ZoneStatus.FAILED]: '#f94f4b',
    [ZoneStatus.COMPLETED]: '#458549',
    [ZoneStatus.DEPRECATED]: '#f94f4b',
    [ZoneStatus.UPGRADING]: '#f9a54b',
};
