import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { useAppSelector } from '../store/hooks';
import { selectSystemConfigurations } from '../store/devices.reducer';
import { updateOnboardDeviceVersion } from '../api/updateOnboardDeviceVersion';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { ToastType } from '../interfaces';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

interface Props {
    open: boolean;
    selectedDeviceIds: string[];
    onUpdateComplete: () => void;
    onClose: () => void;
}
export const UpdateVersionDialog = ({ open, onClose, selectedDeviceIds, onUpdateComplete }: Props) => {
    const [selectedVersion, setSelectedVersion] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const systemConfigurations = useAppSelector(selectSystemConfigurations);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const updateVersions = async () => {
        try {
            setLoading(true);
            await Promise.all(
                selectedDeviceIds.map((deviceId) => {
                    return updateOnboardDeviceVersion(deviceId, selectedVersion!);
                }),
            );

            onUpdateComplete();
        } catch (e) {
            console.error(e);
            setToastMessage({
                message: `При оновленні версій відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Select Version</DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                        {systemConfigurations && (
                            <Autocomplete
                                sx={{ marginTop: 1 }}
                                value={selectedVersion}
                                onChange={(e, newValue) => {
                                    setSelectedVersion(newValue);
                                }}
                                fullWidth
                                size={'small'}
                                renderInput={(params) => <TextField {...params} label="Version" />}
                                options={systemConfigurations.map((c) => c.systemVersion)}
                            />
                        )}
                    </Box>

                    <DialogActions>
                        <Button size={'small'} variant="outlined" onClick={onClose} color="error">
                            Cancel
                        </Button>
                        <Button
                            disabled={loading || !selectedVersion}
                            size={'small'}
                            variant="outlined"
                            onClick={updateVersions}
                            color="primary"
                        >
                            Save
                            {loading && (
                                <span className="ml-2 inline-flex">
                                    <CircularProgress size={15} />
                                </span>
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
