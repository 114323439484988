import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserRoles } from '../interfaces';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { RootState } from './store';
import { Amplify, Auth } from 'aws-amplify';
import config from '../config';
import { getUserRoles } from '../utils/getUserRoles';

Amplify.configure({
    aws_user_pools_id: config.POOL_DATA.UserPoolId,
    aws_user_pools_web_client_id: config.POOL_DATA.ClientId,
});

export interface UserState {
    user?: CognitoUser;
    userRoles?: UserRoles[];
    loading?: boolean;
}

const initialState: UserState = {
    loading: undefined,
};

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userRoles = await getUserRoles(currentUser);

    return { currentUser, userRoles };
});

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<CognitoUser | undefined>) => {
            state.user = action.payload;
        },
        setUserLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<UserRoles[] | undefined>) => {
            state.userRoles = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.currentUser;
                state.userRoles = action.payload.userRoles;
            })
            .addCase(getCurrentUser.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setUserLoading, setUserRoles, setUser } = UserSlice.actions;

export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserRoles = (state: RootState) => state.user.userRoles;

export default UserSlice.reducer;
