import L, {
    Circle,
    LatLngExpression,
    LatLngLiteral,
    LeafletMouseEvent,
    Map,
    Marker,
    Point,
    Polygon,
} from 'leaflet';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetCurrentPosition } from '../hooks/useGetCurrentPosition';
import { AreaGeometryType, Zone, TempZone, LatLng } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
    selectNewZone,
    selectNewZoneActiveTab,
    selectNewZoneCreating,
    selectNewZoneRadius,
    selectSelectedZone,
    selectZones,
    setNewZone,
    setNewZoneSidebarOpened,
} from '../store/zones.reducer';
import { booleanContains, point } from '@turf/turf';
import styled from 'styled-components';
import { throttle } from 'underscore';
import { useGetAvailableAreaPolygon } from '../hooks/useGetAvailableAreaPolygon';

const defaultCenter: LatLngExpression = [50.45287861836015, 30.51755710359059]; // Kiev;

interface HoverInfo {
    point: Point;
    isAvailable: boolean;
}

const TooltipBody = styled.div`
    display: flex;
    z-index: 1000;
    position: absolute;
    background-color: rgba(97, 97, 97, 0.92);
    border-radius: 4px;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding: 4px 8px;
    font-size: 0.6875rem;
    max-width: 300px;
    margin: 2px;
    word-wrap: break-word;
    font-weight: 500;
`;

export const MapPicker = () => {
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | null>(null);
    const newZone = useAppSelector(selectNewZone);
    const selectedZone = useAppSelector(selectSelectedZone);
    const activeTabType = useAppSelector(selectNewZoneActiveTab);
    const newZoneRadius = useAppSelector(selectNewZoneRadius);
    const newZoneCreating = useAppSelector(selectNewZoneCreating);
    const zones = useAppSelector(selectZones);
    const mapRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<Map>();
    const { currentPosition } = useGetCurrentPosition();
    const polygonFeature = useGetAvailableAreaPolygon();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const map = L.map(mapRef.current!);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            subdomains: 'abcd',
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            minZoom: 3,
            maxZoom: 18,
        }).addTo(map);
        map.setZoom(7);
        map.attributionControl.setPosition('bottomleft');
        map.zoomControl.setPosition('bottomright');
        setMap(map);

        return () => {
            map?.remove();
        };
    }, []);

    useEffect(() => {
        if (map) {
            const leafletPolygon = L.geoJSON(polygonFeature);

            leafletPolygon.setStyle({
                fillColor: '#778d31',
                color: '#778d31',
            });
            leafletPolygon.addTo(map);
        }
    }, [map]);

    useEffect(() => {
        if (map) {
            map.setView(currentPosition || defaultCenter);
        }
    }, [map, currentPosition]);

    const isPointInArea = ({ lat, lng }: LatLngLiteral) => {
        const p = point([lng, lat]);
        return booleanContains(polygonFeature, p);
    };

    useEffect(() => {
        if (map) {
            map.on('click', (e) => {
                if (isPointInArea(e.latlng) && !newZoneCreating && zones) {
                    if (activeTabType === AreaGeometryType.Circle) {
                        const { lat, lng } = e.latlng;
                        dispatch(
                            setNewZone({
                                circleAreaGeometry: {
                                    center: {
                                        lat,
                                        lng,
                                    },
                                    radius: newZoneRadius,
                                },
                            }),
                        );
                    } else if (activeTabType === AreaGeometryType.Polygon) {
                        let coordinates: LatLngLiteral[] = [];
                        if (newZone && newZone.polygonAreaGeometry) {
                            coordinates = [...newZone.polygonAreaGeometry.coordinates];
                        }

                        coordinates = [...coordinates, e.latlng];

                        dispatch(
                            setNewZone({
                                polygonAreaGeometry: {
                                    coordinates,
                                },
                            }),
                        );
                    }

                    dispatch(setNewZoneSidebarOpened(true));
                }
            });
        }

        return () => {
            map && map.off('click');
        };
    }, [map, activeTabType, newZoneRadius, newZone, newZoneCreating, zones]);

    useEffect(() => {
        if (map) {
            const onHover = throttle((e: LeafletMouseEvent) => {
                setHoverInfo({
                    point: e.containerPoint,
                    isAvailable: isPointInArea(e.latlng),
                });
            }, 5);

            map.on('mousemove', onHover);
        }

        return () => {
            map && map.off('mousemove');
        };
    }, [map, activeTabType]);

    const drawTargetAreaMarker = (map: Map, targetPosition: LatLngExpression) => {
        const myIcon = L.icon({
            iconUrl: 'https://mapnav-assets.s3.eu-central-1.amazonaws.com/marker.png',
            iconSize: [60, 60],
            iconAnchor: [30, 60],
        });

        return L.marker(targetPosition, {
            icon: myIcon,
        }).addTo(map);
    };

    const drawTargetAreaCircle = (map: Map, targetPosition: LatLngExpression, radius: number) => {
        const circle = L.circle(targetPosition).addTo(map);
        circle.setStyle({
            fillColor: '#f94f4b',
            color: '#f94f4b',
        });
        circle.setRadius(radius * 1000);

        return circle;
    };

    const drawTargetAreaPolygon = (map: Map, coordinates: LatLng[]) => {
        const polygon = L.polygon(coordinates.map((c) => [c.lat, c.lng])).addTo(map);

        polygon.setStyle({
            fillColor: '#f94f4b',
            color: '#f94f4b',
        });

        return polygon;
    };

    useEffect(() => {
        let marker: Marker;
        let circle: Circle;
        let polygon: Polygon;

        if (map && (newZone || selectedZone)) {
            const zone = newZone || (selectedZone as TempZone | Zone);

            const { circleAreaGeometry, polygonAreaGeometry } = zone;
            if (circleAreaGeometry) {
                marker = drawTargetAreaMarker(map, circleAreaGeometry.center);
                circle = drawTargetAreaCircle(map, circleAreaGeometry.center, circleAreaGeometry.radius);

                if (selectedZone) {
                    map.setView(circleAreaGeometry.center);
                }
            } else if (polygonAreaGeometry) {
                polygon = drawTargetAreaPolygon(map, polygonAreaGeometry.coordinates);
                marker = drawTargetAreaMarker(map, polygonAreaGeometry.coordinates[0]);
            }
        }

        return () => {
            if (map) {
                marker?.removeFrom(map);
                circle?.removeFrom(map);
                polygon?.removeFrom(map);
            }
        };
    }, [map, newZone, selectedZone]);

    useEffect(() => {
        mapRef.current!.addEventListener('mouseout', () => {
            setHoverInfo(null);
        });
    }, []);

    const tooltipText = useMemo(() => {
        if (!zones) {
            return 'Список зон завантажується';
        }

        if (newZoneCreating) {
            return 'Зона вже створюється';
        }

        if (hoverInfo) {
            return hoverInfo.isAvailable ? 'Натисніть щоб додати зону' : 'Не доступно';
        }

        return '';
    }, [hoverInfo, newZoneCreating, zones]);

    const tooltipStyle = useMemo(() => {
        if (!newZone && !selectedZone && hoverInfo) {
            return {
                display: 'flex',
                left: hoverInfo.point.x - (hoverInfo.isAvailable ? 80 : 50),
                top: hoverInfo.point.y - 40,
            };
        } else {
            return {
                display: 'none',
            };
        }
    }, [hoverInfo, newZone, selectedZone]);

    return (
        <div className="flex-grow flex h-[100vh] w-full relative" ref={mapRef}>
            <TooltipBody className="tooltip" style={tooltipStyle}>
                {tooltipText}
            </TooltipBody>
        </div>
    );
};
