import { useState } from 'react';
import { LatLngExpression } from 'leaflet';

export const useGetCurrentPosition = () => {
    const [currentPosition, setCurrentPosition] = useState<LatLngExpression>();

    const getPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            setCurrentPosition({ lat, lng });
        });
    };

    return {
        currentPosition,
        getPosition,
    };
};
