import { Field, Form, Formik } from 'formik';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    FormHelperText,
    FormLabel,
} from '@mui/material';
import { useAppDispatch } from '../store/hooks';
import { loadOrganizations } from '../store/organizations.reducer';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { SwatchesPicker } from 'react-color';
import { ToastType } from '../interfaces';
import { createOrganization } from '../api/createOrganization';

interface OrganizationForm {
    name?: string;
    labelColor?: string;
}

interface AddOrganizationDialogProps {
    open: boolean;
    onClose: () => void;
}

export const AddOrganizationDialog = ({ open, onClose }: AddOrganizationDialogProps) => {
    const dispatch = useAppDispatch();
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const initialValues: OrganizationForm = {
        name: '',
        labelColor: '',
    };

    const validateForm = (values: any) => {
        const errors: OrganizationForm = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        if (!values.labelColor) {
            errors.labelColor = 'Required';
        }

        return errors;
    };

    const handleSubmit = async (values: OrganizationForm, { setSubmitting }: any) => {
        try {
            await createOrganization({
                name: values.name!,
                labelColor: values.labelColor!,
            });
            dispatch(loadOrganizations());
            onClose();
        } catch (error) {
            setToastMessage({
                message: `Під час створенні конфігурації відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add Organization</DialogTitle>
            <DialogContent sx={{ width: 370 }}>
                <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
                    {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <Field
                                        as={TextField}
                                        name="name"
                                        label="Organization Name *"
                                        variant="outlined"
                                        size="small"
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </FormControl>

                                <Box sx={{ marginBottom: 2 }}>
                                    <div style={{ marginBottom: '10px' }}>
                                        <FormLabel
                                            className={`${
                                                touched.labelColor && !!errors.labelColor ? 'Mui-error' : ''
                                            }`}
                                        >
                                            Organization Label Color
                                        </FormLabel>
                                    </div>

                                    <SwatchesPicker
                                        color={values['labelColor']}
                                        onChange={(color) => {
                                            setFieldValue('labelColor', color.hex);
                                        }}
                                    />

                                    {touched.labelColor && !!errors.labelColor && (
                                        <FormHelperText
                                            style={{
                                                margin: '4px 14px 0',
                                            }}
                                            className={'Mui-error'}
                                        >
                                            {touched.labelColor && errors.labelColor}
                                        </FormHelperText>
                                    )}
                                </Box>

                                <DialogActions>
                                    <Button size="small" variant="outlined" onClick={onClose} color="error">
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="outlined"
                                        disabled={isSubmitting}
                                        color="primary"
                                    >
                                        Add
                                        {isSubmitting && (
                                            <CircularProgress style={{ marginLeft: '5px' }} size={15} />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </Dialog>
    );
};
