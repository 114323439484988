import React, { useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { ToastType } from '../interfaces';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { createOnboardDevice } from '../api/createOnboardDevice';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

interface Props {
    open: boolean;
    onCreationCompleted: () => void;
    onClose: () => void;
}

function isInteger(str: string) {
    const regex = /^-?\d+$/;
    return regex.test(str);
}

export const CreateDevicesDialog = ({ open, onClose, onCreationCompleted }: Props) => {
    const [devicesCount, setDevicesCount] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const isOverMaximumAllowed = useMemo(() => {
        return parseInt(devicesCount) > 100;
    }, [devicesCount]);

    const triggerDevicesCreation = async () => {
        setLoading(true);

        const counter = parseInt(devicesCount);

        for (let i = 1; i <= counter; i++) {
            try {
                await createOnboardDevice();
            } catch (e) {
                console.error(`При створенні девайсу ${i} відбулася помилка`);
                console.error(e);
                setToastMessage({
                    message: `При створенні девайсу ${i} відбулася помилка`,
                    type: ToastType.WARNING,
                });
            } finally {
                setProgress(i / counter);
            }
        }

        setLoading(false);
        onCreationCompleted();
        setProgress(0);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Create devices</DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    <Box sx={{ marginTop: 1, marginBottom: 2 }}>
                        <TextField
                            label="Enter count"
                            error={isOverMaximumAllowed}
                            helperText={
                                isOverMaximumAllowed ? 'Максимум дозволено 100 шт за одну операцію' : ''
                            }
                            size={'small'}
                            fullWidth={true}
                            value={devicesCount}
                            inputProps={{ type: 'number' }}
                            onChange={(event) => {
                                if (!event.target.value) {
                                    setDevicesCount('');
                                }

                                if (!isInteger(event.target.value)) {
                                    event.preventDefault();
                                    return;
                                }

                                setDevicesCount(parseInt(event.target.value).toString());
                            }}
                        />
                    </Box>

                    {loading && (
                        <Box sx={{ width: '100%', marginBottom: 1 }}>
                            <LinearProgressWithLabel value={progress * 100} />
                        </Box>
                    )}

                    <DialogActions>
                        <Button
                            disabled={loading}
                            size={'small'}
                            variant="outlined"
                            onClick={onClose}
                            color="error"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={loading || !devicesCount || isOverMaximumAllowed}
                            size={'small'}
                            variant="outlined"
                            onClick={triggerDevicesCreation}
                            color="primary"
                        >
                            Create
                            {loading && (
                                <span className="ml-2 inline-flex">
                                    <CircularProgress size={15} />
                                </span>
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
