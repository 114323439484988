import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import ToastWrapper from './ToastWrapper';
import { useToastMessage } from '../hooks/useToastMessage';
import { ToastType } from '../interfaces';
import Box from '@mui/material/Box';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import { deleteOnboardDevice } from '../api/deleteOnboardDevice';
import Typography from '@mui/material/Typography';

interface Props {
    open: boolean;
    selectedDeviceIds: string[];
    onRemovalCompleted: () => void;
    onClose: () => void;
}

export const RemoveDevicesDialog = ({ open, onClose, onRemovalCompleted, selectedDeviceIds }: Props) => {
    const [progress, setProgress] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const triggerDevicesRemoval = async () => {
        setLoading(true);

        const counter = selectedDeviceIds.length;

        for (let i = 0; i < counter; i++) {
            try {
                await deleteOnboardDevice(selectedDeviceIds[i]);
            } catch (e) {
                console.error(`При видаленні девайсу з айді ${selectedDeviceIds[i]} відбулася помилка`);
                console.error(e);
                setToastMessage({
                    message: `При видаленні девайсу з айді ${selectedDeviceIds[i]} відбулася помилка`,
                    type: ToastType.WARNING,
                });
            } finally {
                setProgress(i / counter);
            }
        }

        setLoading(false);
        onRemovalCompleted();
        setProgress(0);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Delete devices</DialogTitle>
                <DialogContent sx={{ width: 350 }}>
                    <Box sx={{ width: '100%', marginBottom: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                            Ви впевнені, що хочете видалити вибрані девайси?
                        </Typography>
                    </Box>
                    {loading && (
                        <Box sx={{ width: '100%', marginBottom: 1 }}>
                            <LinearProgressWithLabel value={progress * 100} />
                        </Box>
                    )}

                    <DialogActions>
                        <Button
                            disabled={loading}
                            size={'small'}
                            variant="outlined"
                            onClick={onClose}
                            color="error"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            size={'small'}
                            variant="outlined"
                            onClick={triggerDevicesRemoval}
                            color="primary"
                        >
                            Delete
                            {loading && (
                                <span className="ml-2 inline-flex">
                                    <CircularProgress size={15} />
                                </span>
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </>
    );
};
