import ListItem from '@mui/material/ListItem';
import { IconButton, ListItemButton, Tooltip } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { memo, useState } from 'react';
import { useAppDispatch } from '../store/hooks';
import { loadZones, setSelectedZone } from '../store/zones.reducer';
import { ZoneStatusBadge } from './ZoneStatusBadge';
import { ToastType, Zone, ZoneStatus } from '../interfaces';
import { deleteZone } from '../api/deleteZone';
import { useToastMessage } from '../hooks/useToastMessage';
import ToastWrapper from './ToastWrapper';
import { Badge } from './Badge';
import DeleteIcon from '@mui/icons-material/Delete';

interface ZonesHistoryItemProps {
    zone: Zone;
}

export const ZoneHistoryItem = memo(({ zone }: ZonesHistoryItemProps) => {
    const dispatch = useAppDispatch();

    const [isDeletingZone, setIsDeletingZone] = useState<boolean>(false);
    const { isOpened, toastMessage, setToastMessage, handleCloseToast } = useToastMessage();

    const triggerDeleteZone = async (zone: Zone) => {
        setIsDeletingZone(true);
        try {
            await deleteZone(zone._id);
            dispatch(loadZones());
        } catch (e) {
            console.error('Delete zone failed', e);
            setToastMessage({
                message: `При видаленні сессії відбулася помилка`,
                type: ToastType.WARNING,
            });
        } finally {
            setIsDeletingZone(false);
        }
    };

    return (
        <ListItem
            disablePadding
            secondaryAction={
                <>
                    {zone.status !== ZoneStatus.PROCESSING && (
                        <span>
                            <Tooltip title="Видалити зону" placement="top">
                                <IconButton
                                    disabled={isDeletingZone}
                                    size="small"
                                    onClick={async () => {
                                        await triggerDeleteZone(zone);
                                    }}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </span>
                    )}
                </>
            }
        >
            <ListItemButton
                onClick={() => {
                    dispatch(setSelectedZone(zone));
                }}
                dense
            >
                <div>
                    <ListItemText
                        primary={`${zone.name}`}
                        secondary={
                            <>
                                {zone.models &&
                                    Object.keys(zone.models).map((modelId, index) => {
                                        return (
                                            <Badge
                                                key={index}
                                                className={'mr-1 mb-1'}
                                                backgroundColor={'rgb(75 130 249)'}
                                            >
                                                {modelId}
                                            </Badge>
                                        );
                                    })}
                                <span style={{ display: 'block' }}>
                                    <ZoneStatusBadge zone={zone} />
                                </span>
                            </>
                        }
                    />
                </div>
            </ListItemButton>
            <ToastWrapper
                open={isOpened}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={3000}
                onClose={handleCloseToast}
                message={toastMessage?.message}
                type={toastMessage?.type}
            />
        </ListItem>
    );
});
