interface Config {
    API_URL: string;
    POOL_DATA: {
        UserPoolId: string;
        ClientId: string;
    };
}

export default {
    API_URL: process.env.REACT_APP_API_URL,
    POOL_DATA: {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    },
} as Config;
