import { Zone, ZoneStatus } from '../interfaces';
import { CircularProgress, Tooltip } from '@mui/material';
import { StatusColor } from './constants';
import { Badge } from './Badge';

interface ZoneStatusBadgeProps {
    zone: Zone;
}

const StatusText: any = {
    [ZoneStatus.CREATED]: 'створення',
    [ZoneStatus.PROCESSING]: 'в обробці',
    [ZoneStatus.FAILED]: 'помилка',
    [ZoneStatus.COMPLETED]: 'готово',
    [ZoneStatus.DEPRECATED]: 'застаріло',
    [ZoneStatus.UPGRADING]: 'оновлення',
};

const TooltipStatusText: any = {
    [ZoneStatus.COMPLETED]: 'Зона успішно створена.',
    [ZoneStatus.CREATED]: 'Сервер ініціалізує зону. Це може зайняти якийсь час.',
    [ZoneStatus.PROCESSING]: 'Сервер обробляє зону. Це може зайняти якийсь час.',
    [ZoneStatus.FAILED]: 'При створенні зони відбулася помилка.',
    [ZoneStatus.DEPRECATED]: 'Зону необхідно оновити до останньої версії.',
    [ZoneStatus.UPGRADING]: 'Сервер оновлює зону. Це може зайняти якийсь час.',
};

export const ZoneStatusBadge = ({ zone }: ZoneStatusBadgeProps) => {
    const status = zone.status;

    return (
        <Tooltip title={TooltipStatusText[status]} placement="top">
            <Badge backgroundColor={StatusColor[status]}>
                <>
                    {StatusText[status]}{' '}
                    {(status === ZoneStatus.CREATED ||
                        status === ZoneStatus.UPGRADING ||
                        status === ZoneStatus.PROCESSING) && (
                        <CircularProgress className="ml-2 mt-[2px]" size={15} sx={{ color: 'white' }} />
                    )}
                </>
            </Badge>
        </Tooltip>
    );
};
