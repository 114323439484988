import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dialog, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useAppSelector } from '../store/hooks';
import { selectSystemConfigurations } from '../store/devices.reducer';
import { useMemo } from 'react';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';

interface Props {
    open: boolean;
    version: string;
    onClose: () => void;
}

export const VersionDialog = ({ version, open, onClose }: Props) => {
    const systemConfigurations = useAppSelector(selectSystemConfigurations);

    const configuration = useMemo(() => {
        if (systemConfigurations) {
            const configuration = systemConfigurations.find(
                (c) => c.systemVersion === version,
            )?.configuration;

            return {
                ...configuration,
                systemVersion: version,
            };
        }
    }, [systemConfigurations]);

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'systemVersion' },
            { key: 'applicationVersion' },
            { key: 'containerVersion' },
            { key: 'mapVersion' },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                width: 150,
            };
        });
    }, []);

    const rows = [
        {
            ...configuration,
            id: 1,
        },
    ];

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <Box sx={{ width: '600px' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooterPagination={true}
                />
            </Box>
        </Dialog>
    );
};
