import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';

import jsPDF from 'jspdf';
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    getGridNumericOperators,
    getGridStringOperators,
    getGridDateOperators,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPaginationModel,
    GridFilterModel,
} from '@mui/x-data-grid';
import { ActivationStick, Device, DeviceWithSticks, UserRoles } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import { ActivationSticksDialog } from './ActivationSticksDialog';
import { GridColType } from '@mui/x-data-grid/models/colDef/gridColType';
import Button from '@mui/material/Button';
import { UpdateVersionDialog } from './UpdateVersionDialog';
import {
    loadActivationSticks,
    loadOnboardDevices,
    loadSystemConfigurations,
    selectOnboardDevices,
    selectSelectedOnboardDeviceTableRows,
    setSelectedOnboardDeviceTableRows,
} from '../store/devices.reducer';
import { Done as DoneIcon, Clear as ClearIcon } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AddHomeIcon from '@mui/icons-material/AddHome';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyIcon from '@mui/icons-material/Key';

enum VersionValidity {
    NOT_VALID = 0,
    VALID = 1,
}

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { VersionDialog } from './VersionDialog';
import { GetSecretsDialog } from './GetSecretsDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import styled from 'styled-components';
import { hasAllowedRole } from '../utils/hasAllowedRole';
import { useCognitoUser } from '../hooks/useCognitoUser';
import { AttachOrganizationDialog } from './AttachOrganizationDialog';
import { CreateDevicesDialog } from './CreateDevicesDialog';
import { formatTableCellDate } from '../utils/formatTableCellDate';
import { RemoveDevicesDialog } from './RemoveDevicesDialog';

interface DataGridComponentProps {
    devices: DeviceWithSticks[];
}

export const isVersionTheSame = (collection: ActivationStick[], parentDeviceVersion: string) => {
    return collection.every((c) => c.systemVersion === parentDeviceVersion);
};

const LongTextCellWrapper = styled.div`
    position: relative;
    padding-right: 40px;
    display: flex;

    & .copy-btn {
        display: none;
    }

    &:hover .copy-btn {
        display: block;
    }
`;

function exportToFile(devices: Device[]) {
    const formattedData = devices.map((device) => {
        return `${device.deviceSerial}\t${device.deviceId}`;
    });

    const fileContent = formattedData.join('\n');

    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Devices.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function transformToTable(array: any[], numColumns: number) {
    const numRows = Math.ceil(array.length / numColumns);
    const table = [];

    for (let i = 0; i < numRows; i++) {
        const row = array.slice(i * numColumns, (i + 1) * numColumns);

        // If the last row is shorter than numColumns, pad it with null or a desired value
        while (row.length < numColumns) {
            row.push(null); // or another value such as 0 or an empty string
        }

        table.push(row);
    }

    return table;
}

function exportToPdf(devices: Device[]) {
    const doc = new jsPDF();
    const columns = 6;
    const rowsPerPage = 15; // Number of rows per page
    let currentPage = 1;

    const tableData = transformToTable(devices, columns);

    const addPageWithHeader = (doc: jsPDF, pageNum: number) => {
        if (pageNum > 1) {
            doc.addPage();
        }
        doc.setFontSize(11.8);
    };

    addPageWithHeader(doc, currentPage);

    let y = 23;
    let x = 10;
    const cellWidth = 31.7;
    const cellHeight = 17.5;
    let rowCount = 0;

    doc.setDrawColor(200, 200, 200);

    tableData.forEach((row) => {
        if (rowCount >= rowsPerPage) {
            currentPage++;
            addPageWithHeader(doc, currentPage);
            y = 23;
            rowCount = 0;
        }

        x = 10; // Reset x for each row
        row.forEach((cell) => {
            doc.rect(x, y - 8, cellWidth, cellHeight);
            if (cell) {
                const text = `${cell.deviceId}`;
                const textWidth = doc.getTextWidth(text);
                const textX = x + (cellWidth - textWidth - 3);
                doc.text(text, textX, y + 5.5);
            }
            x += cellWidth;
        });
        y += cellHeight;
        rowCount++;
    });

    doc.save('Devices.pdf');
}

function Toolbar() {
    const dispatch = useAppDispatch();
    const { userRoles } = useCognitoUser();
    const selectedRows = useAppSelector(selectSelectedOnboardDeviceTableRows);
    const onboardDevices = useAppSelector(selectOnboardDevices);
    const [isUpdateVersionDialogOpen, setIsUpdateVersionDialogOpen] = useState(false);
    const [getSecretsDialogOpen, setGetSecretsDialogOpen] = useState(false);
    const [attachOrganizationDialogOpen, setAttachOrganizationDialogOpen] = useState(false);
    const [createDevicesDialogOpen, setCreateDevicesDialogOpen] = useState(false);
    const [removeDevicesDialogOpen, setRemoveDevicesDialogOpen] = useState(false);

    const onVersionChange = () => {
        setIsUpdateVersionDialogOpen(true);
    };

    const onGetSecrets = () => {
        setGetSecretsDialogOpen(true);
    };

    const onAttachOrganization = () => {
        setAttachOrganizationDialogOpen(true);
    };

    const onCreateDevices = () => {
        setCreateDevicesDialogOpen(true);
    };

    const onRemoveDevices = () => {
        setRemoveDevicesDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadOnboardDevices());
        dispatch(loadActivationSticks());
        dispatch(loadSystemConfigurations());
    };

    const isAdmin = useMemo(() => {
        return userRoles && hasAllowedRole(userRoles, [UserRoles.ADMINS]);
    }, [userRoles]);

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {isAdmin && (
                    <Button
                        startIcon={<UpgradeIcon />}
                        disabled={selectedRows.length === 0}
                        color="primary"
                        onClick={onVersionChange}
                    >
                        Change Version
                    </Button>
                )}
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button
                    disabled={selectedRows.length !== 1}
                    startIcon={<KeyIcon />}
                    color="primary"
                    onClick={onGetSecrets}
                >
                    Get Secrets
                </Button>
                {isAdmin && (
                    <Button
                        disabled={selectedRows.length === 0}
                        startIcon={<AddHomeIcon />}
                        color="primary"
                        onClick={onAttachOrganization}
                    >
                        Attach Organization
                    </Button>
                )}
                <Button startIcon={<AddIcon />} color="primary" onClick={onCreateDevices}>
                    Create Devices
                </Button>

                <Button
                    disabled={selectedRows.length === 0}
                    startIcon={<RemoveIcon />}
                    color="primary"
                    onClick={onRemoveDevices}
                >
                    Remove Devices
                </Button>

                <Button
                    startIcon={<AddIcon />}
                    color="primary"
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                        const selectedDevices = selectedRows.map(
                            (id) => onboardDevices?.find((d) => d.deviceId == id) as Device,
                        );

                        exportToFile(selectedDevices);
                        exportToPdf(selectedDevices);
                    }}
                >
                    Export Ids
                </Button>
            </GridToolbarContainer>
            {isUpdateVersionDialogOpen && (
                <UpdateVersionDialog
                    selectedDeviceIds={selectedRows}
                    open={isUpdateVersionDialogOpen}
                    onClose={() => setIsUpdateVersionDialogOpen(false)}
                    onUpdateComplete={() => {
                        setIsUpdateVersionDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}
            {getSecretsDialogOpen && (
                <GetSecretsDialog
                    selectedDeviceId={selectedRows[0]}
                    open={getSecretsDialogOpen}
                    onClose={() => setGetSecretsDialogOpen(false)}
                />
            )}

            {attachOrganizationDialogOpen && (
                <AttachOrganizationDialog
                    selectedDeviceIds={selectedRows}
                    open={attachOrganizationDialogOpen}
                    onClose={() => setAttachOrganizationDialogOpen(false)}
                    onAttachComplete={() => {
                        setAttachOrganizationDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}

            {createDevicesDialogOpen && (
                <CreateDevicesDialog
                    open={createDevicesDialogOpen}
                    onClose={() => setCreateDevicesDialogOpen(false)}
                    onCreationCompleted={() => {
                        setCreateDevicesDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}

            {removeDevicesDialogOpen && (
                <RemoveDevicesDialog
                    open={removeDevicesDialogOpen}
                    selectedDeviceIds={selectedRows}
                    onClose={() => setRemoveDevicesDialogOpen(false)}
                    onRemovalCompleted={() => {
                        setRemoveDevicesDialogOpen(false);
                        dispatch(loadOnboardDevices());
                    }}
                />
            )}
        </>
    );
}

const getGridPaginationModel = () => {
    const gridPaginationModel = localStorage.getItem('onboardDevicesTableGridPaginationModel');
    return gridPaginationModel
        ? JSON.parse(gridPaginationModel)
        : ({ pageSize: 10, page: 0 } as GridPaginationModel);
};

const getGridFilterModel = () => {
    const gridFilterModel = localStorage.getItem('onboardDevicesTableGridFilterModel');
    return gridFilterModel ? JSON.parse(gridFilterModel) : ({ items: [] } as GridFilterModel);
};

export const OnboardDevicesTableGrid = ({ devices }: DataGridComponentProps) => {
    const dispatch = useAppDispatch();
    const selectedRows = useAppSelector(selectSelectedOnboardDeviceTableRows);
    const [gridPaginationModel, setGridPaginationModel] = useState<GridPaginationModel>(
        getGridPaginationModel(),
    );

    const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>(getGridFilterModel());

    const [columnsConfig, setColumnsConfig] = useState<any>({});
    const [selectedDevice, setSelectedDevice] = useState<DeviceWithSticks | null>(null);
    const [isActivationSticksDialogOpen, setIsActivationSticksDialogOpen] = useState<boolean>(false);
    const [isVersionDialogOpen, setIsVersionDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        const initialConfig = localStorage.getItem('onboardDevicesTableColumnsConfig');
        initialConfig && setColumnsConfig(JSON.parse(initialConfig));
    }, []);

    useEffect(() => {
        if (gridPaginationModel) {
            localStorage.setItem(
                'onboardDevicesTableGridPaginationModel',
                JSON.stringify(gridPaginationModel),
            );
        }
    }, [gridPaginationModel]);

    useEffect(() => {
        if (gridFilterModel) {
            localStorage.setItem('onboardDevicesTableGridFilterModel', JSON.stringify(gridFilterModel));
        }
    }, [gridFilterModel]);

    const handleColumnVisibilityChange = (newConfig: any) => {
        newConfig.deviceId = true; // newer hide
        newConfig.__check__ = true; // newer hide
        setColumnsConfig(newConfig);
        const jsonData = JSON.stringify(newConfig);
        localStorage.setItem('onboardDevicesTableColumnsConfig', jsonData);
    };

    const handleActivationSticksDialogClose = () => {
        setSelectedDevice(null);
        setIsActivationSticksDialogOpen(false);
    };

    const handleIsVersionDialogClose = () => {
        setSelectedDevice(null);
        setIsVersionDialogOpen(false);
    };

    const sticksCell = (cellValues: GridCellParams) => {
        return (
            <div
                style={{ fontWeight: 500, cursor: cellValues.value !== 0 ? 'pointer' : '' }}
                onClick={() => {
                    if (!cellValues.value) {
                        return;
                    }
                    setSelectedDevice(cellValues.row);
                    setIsActivationSticksDialogOpen(true);
                }}
            >
                <span>{cellValues.value as number}</span>
            </div>
        );
    };

    const copyTextCell = (cellValues: GridCellParams) => {
        const [title, setTitle] = useState<string>('Скопіювати');

        const onCopy = () => {
            copyTextToClipboard(cellValues.value as string);
            setTitle('Скопійовано');
        };

        return (
            <LongTextCellWrapper>
                {cellValues.value ? (
                    <>
                        <span
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {cellValues.value as string}
                        </span>

                        <span className="copy-btn">
                            <Tooltip title={title} placement="top">
                                <IconButton
                                    onMouseLeave={() => {
                                        setTitle('Скопіювати');
                                    }}
                                    onClick={onCopy}
                                    size={'small'}
                                    sx={{ marginLeft: 1 }}
                                    aria-label="copy"
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </>
                ) : (
                    <></>
                )}
            </LongTextCellWrapper>
        );
    };

    const versionsCell = (cellValues: GridCellParams) => {
        return (
            <div
                style={{ fontWeight: 500, cursor: cellValues.value !== 0 ? 'pointer' : '' }}
                onClick={() => {
                    if (!cellValues.value) {
                        return;
                    }
                    setSelectedDevice(cellValues.row);
                    setIsVersionDialogOpen(true);
                }}
            >
                <span>{cellValues.value as number}</span>
            </div>
        );
    };

    const upToDateCell = (cellValues: GridCellParams) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {cellValues.value === VersionValidity.VALID ? (
                    <DoneIcon style={{ color: '#458549' }} />
                ) : (
                    <ClearIcon style={{ color: '#f94f4b' }} />
                )}
            </div>
        );
    };

    const organizationLabelCell = (cellValues: GridCellParams) => {
        const labelColor = useMemo(() => {
            return cellValues.row.organizationDetails?.labelColor || '#5a5b5d';
        }, [cellValues]);

        return (
            <div>
                <span
                    style={{
                        backgroundColor: labelColor,
                        color: '#fff',
                        padding: '6px 10px',
                        borderRadius: '15px',
                    }}
                >
                    {cellValues.value as string}
                </span>
            </div>
        );
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                key: 'deviceSerial',
                type: 'number',
                filterOperators: getGridNumericOperators,
                renderCell: copyTextCell,
            },
            { key: 'deviceId', renderCell: copyTextCell },
            { key: 'organization', renderCell: organizationLabelCell },
            { key: 'status', renderCell: copyTextCell },
            { key: 'systemVersion', renderCell: versionsCell },
            {
                key: 'upToDate',
                renderCell: upToDateCell,
            },
            {
                key: 'createdAt',
                valueFormatter: formatTableCellDate,
                filterOperators: getGridDateOperators,
            },
            {
                key: 'updatedAt',
                valueFormatter: formatTableCellDate,
                filterOperators: getGridDateOperators,
            },
            {
                key: 'activationSticksCount',
                type: 'number',
                filterOperators: getGridNumericOperators,
                renderCell: sticksCell,
            },
            { key: 'baseImageS3Link', renderCell: copyTextCell },
            { key: 'commitHash', renderCell: copyTextCell },
            { key: 'zymkeySerial', renderCell: copyTextCell },
            { key: 'rpiSerial', renderCell: copyTextCell },
            { key: 'username', renderCell: copyTextCell },
            { key: 'hostname', renderCell: copyTextCell },
            { key: 'sshPort', filterOperators: getGridNumericOperators, renderCell: copyTextCell },
            { key: 'accessPointSSID', renderCell: copyTextCell },
            { key: 'vpnProfile', renderCell: copyTextCell },
            { key: 'vpnIPAddress', renderCell: copyTextCell },
            { key: 'vpnPubkey', renderCell: copyTextCell },
            { key: 'bundleDevicePubkey', renderCell: copyTextCell },
            { key: 'bundleCloudPubkey', renderCell: copyTextCell },
            { key: 'mapS3Link', renderCell: copyTextCell },
            { key: 'zymkeyPubkey', renderCell: copyTextCell },
            { key: 'deviceSSHPubkey', renderCell: copyTextCell },
            { key: 'deviceSSHPubkeyType', renderCell: copyTextCell },
            { key: 'deviceSSHPubkeyHash', renderCell: copyTextCell },
        ].map((col) => {
            return {
                field: col.key,
                type: (col.type || 'string') as GridColType,
                headerName: transformKeyToTitle(col.key),
                renderCell: col.renderCell,
                valueFormatter: col.valueFormatter,
                width: 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterOperators: col.filterOperators ? col.filterOperators() : getGridStringOperators(),
            };
        });
    }, []);

    const rows = devices.map((device) => {
        const row: any = {
            ...device,
            activationSticksCount: device.activationSticks.length,
            status: device.status || 'Unknown',
            id: device.deviceId,
        };

        row.organization = device.organization || 'None';

        if (device.systemVersion && isVersionTheSame(device.activationSticks, device.systemVersion)) {
            row.upToDate = VersionValidity.VALID;
        } else {
            row.upToDate = VersionValidity.NOT_VALID;
        }

        if (row.updatedAt) row.updatedAt = new Date(row.updatedAt);
        if (row.createdAt) row.createdAt = new Date(row.createdAt);

        return row;
    });

    const onRowSelectionModelChange = (rows: any[]) => {
        dispatch(setSelectedOnboardDeviceTableRows(rows));
    };

    return (
        <>
            <Box sx={{ width: '100%', minHeight: '200px' }} tabIndex={0}>
                <DataGrid
                    style={{ minHeight: '200px' }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: gridPaginationModel,
                        },
                    }}
                    slots={{
                        toolbar: Toolbar as GridSlots['toolbar'],
                    }}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    rowSelectionModel={selectedRows}
                    pageSizeOptions={[5, 10, 25, 100]}
                    pagination
                    checkboxSelection
                    onPaginationModelChange={(model: GridPaginationModel) => {
                        setGridPaginationModel(model);
                    }}
                    filterModel={gridFilterModel}
                    onFilterModelChange={(model: GridFilterModel) => {
                        setGridFilterModel(model);
                    }}
                    onCellKeyDown={(cell, event) => {
                        // Override row selection
                        if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
                            navigator.clipboard
                                .writeText((cell.value || '').toString())
                                .catch((err) => console.error('Failed to copy text: ', err));
                        }
                    }}
                    disableRowSelectionOnClick
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    columnVisibilityModel={columnsConfig}
                />

                {isActivationSticksDialogOpen && selectedDevice && (
                    <ActivationSticksDialog
                        device={selectedDevice}
                        open={isActivationSticksDialogOpen}
                        onClose={handleActivationSticksDialogClose}
                    />
                )}
                {isVersionDialogOpen && selectedDevice && (
                    <VersionDialog
                        version={selectedDevice.systemVersion!}
                        open={isVersionDialogOpen}
                        onClose={handleIsVersionDialogClose}
                    />
                )}
            </Box>
        </>
    );
};
