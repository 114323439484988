import axios from 'axios';
import config from '../config';

export const updateOnboardDeviceVersion = (deviceId: string, systemVersion: string) => {
    const data = {
        systemVersion,
    };

    return axios.put(`${config.API_URL}/onboard-devices/${deviceId}`, data).then((response: any) => {
        return response.data.data;
    });
};
