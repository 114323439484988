import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DeviceWithSticks } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';

interface Props {
    open: boolean;
    device: DeviceWithSticks;
    onClose: () => void;
}

const columnKeys = ['activationStickId', 'systemVersion'];

export const ActivationSticksDialog = ({ device, open, onClose }: Props) => {
    const sticks = device.activationSticks;

    const columns = columnKeys.map((key) => ({
        field: key,
        headerName: transformKeyToTitle(key),
        width: 300,
    }));

    const rows = sticks.map((stick) => {
        return {
            ...stick,
            id: stick.activationStickId,
        };
    });

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={onClose} />
            </DialogTitle>
            <Box sx={{ width: '600px' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                    getRowClassName={(params) =>
                        params.row.systemVersion !== device.systemVersion ? 'invalidRowHighlight' : ''
                    }
                />
            </Box>
        </Dialog>
    );
};
