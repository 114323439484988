import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridCellParams,
} from '@mui/x-data-grid';
import { Organization } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import { Add as AddIcon } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch } from '../store/hooks';
import { loadOrganizations } from '../store/organizations.reducer';
import { AddOrganizationDialog } from './AddOrganizationDialog';

interface DataGridComponentProps {
    organizations: Organization[];
}

function Toolbar() {
    const dispatch = useAppDispatch();

    const [addOrganizationDialogOpen, setAddOrganizationDialogOpen] = useState(false);

    const onAdd = () => {
        setAddOrganizationDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadOrganizations());
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>
            </GridToolbarContainer>
            {addOrganizationDialogOpen && (
                <AddOrganizationDialog
                    open={addOrganizationDialogOpen}
                    onClose={() => setAddOrganizationDialogOpen(false)}
                />
            )}
        </>
    );
}

export const OrganizationsTableGrid = ({ organizations }: DataGridComponentProps) => {
    const [columnsConfig, setColumnsConfig] = useState<any>({});

    useEffect(() => {
        const initialConfig = localStorage.getItem('organizationsTableColumnsConfig');
        initialConfig && setColumnsConfig(JSON.parse(initialConfig));
    }, []);

    const handleColumnVisibilityChange = (newConfig: any) => {
        newConfig.__check__ = true; // newer hide
        setColumnsConfig(newConfig);
        const jsonData = JSON.stringify(newConfig);
        localStorage.setItem('organizationsTableColumnsConfig', jsonData);
    };

    const organizationLabelCell = (cellValues: GridCellParams) => {
        return (
            <div>
                <span
                    style={{
                        backgroundColor: cellValues.row.labelColor,
                        color: '#fff',
                        padding: '6px 10px',
                        borderRadius: '15px',
                    }}
                >
                    {cellValues.value as string}
                </span>
            </div>
        );
    };

    const columns: GridColDef[] = useMemo(() => {
        return [{ key: 'name', renderCell: organizationLabelCell }].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                width: 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterable: true,
                renderCell: col.renderCell,
            };
        });
    }, []);

    const rows = organizations.map((o) => {
        const row: any = {
            ...o,
            id: o.name,
        };

        return row;
    });

    return (
        <Box sx={{ width: '100%', height: '671px' }} tabIndex={0}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: Toolbar as GridSlots['toolbar'],
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                columnVisibilityModel={columnsConfig}
            />
        </Box>
    );
};
