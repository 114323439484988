import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridSlots,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { SystemConfiguration } from '../interfaces';
import { transformKeyToTitle } from '../utils/transformKeyToTitle';
import Button from '@mui/material/Button';
import { Add as AddIcon } from '@mui/icons-material';
import { AddSystemConfigurationDialog } from './AddSystemConfigurationDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import { loadSystemConfigurations } from '../store/devices.reducer';
import { useAppDispatch } from '../store/hooks';

interface DataGridComponentProps {
    configurations: SystemConfiguration[];
}

function Toolbar() {
    const dispatch = useAppDispatch();

    const [addConfigurationDialogOpen, setAddConfigurationDialogOpen] = useState(false);

    const onAdd = () => {
        setAddConfigurationDialogOpen(true);
    };

    const onReload = () => {
        dispatch(loadSystemConfigurations());
    };

    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Button startIcon={<RefreshIcon />} color="primary" onClick={onReload}>
                    Reload
                </Button>
                <Button startIcon={<AddIcon />} color="primary" onClick={onAdd}>
                    Add
                </Button>
            </GridToolbarContainer>
            {addConfigurationDialogOpen && (
                <AddSystemConfigurationDialog
                    open={addConfigurationDialogOpen}
                    onClose={() => setAddConfigurationDialogOpen(false)}
                />
            )}
        </>
    );
}

export const SystemConfigurationsTableGrid = ({ configurations }: DataGridComponentProps) => {
    const [columnsConfig, setColumnsConfig] = useState<any>({});

    useEffect(() => {
        const initialConfig = localStorage.getItem('systemConfigurationsTableColumnsConfig');
        initialConfig && setColumnsConfig(JSON.parse(initialConfig));
    }, []);

    const handleColumnVisibilityChange = (newConfig: any) => {
        newConfig.deviceId = true; // newer hide
        newConfig.__check__ = true; // newer hide
        setColumnsConfig(newConfig);
        const jsonData = JSON.stringify(newConfig);
        localStorage.setItem('systemConfigurationsTableColumnsConfig', jsonData);
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            { key: 'systemVersion' },
            { key: 'applicationVersion' },
            { key: 'mapVersion' },
            { key: 'containerVersion' },
        ].map((col) => {
            return {
                field: col.key,
                headerName: transformKeyToTitle(col.key),
                width: 200,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                filterable: true,
            };
        });
    }, []);

    const rows = configurations.map((c) => {
        const row: any = {
            systemVersion: c.systemVersion,
            ...c.configuration,
            id: c.systemVersion,
        };

        return row;
    });

    return (
        <Box sx={{ width: '100%', height: '671px' }} tabIndex={0}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: Toolbar as GridSlots['toolbar'],
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                columnVisibilityModel={columnsConfig}
            />
        </Box>
    );
};
