import { CircularProgress } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import Typography from '@mui/material/Typography/Typography';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadZones, selectZones } from '../store/zones.reducer';
import { useEffect, useMemo } from 'react';
import { ZoneHistoryItem } from './ZoneHistoryItem';

export const ZonesHistory = () => {
    const dispatch = useAppDispatch();
    const zones = useAppSelector(selectZones);

    useEffect(() => {
        const timeoutId = setInterval(() => {
            dispatch(loadZones());
        }, 3000);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    const sortedZones = useMemo(() => {
        if (zones) {
            return [...zones].sort(
                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
            );
        }
    }, [zones]);

    return (
        <>
            <Typography className="p-5 pb-0 pt-6 !mb-0" variant="h5" gutterBottom>
                Збережені зони
            </Typography>
            <div className="overflow-y-auto pb-6 pl-1 pr-1">
                {sortedZones && (
                    <List>
                        {sortedZones.map((zone, index) => (
                            <ZoneHistoryItem key={index} zone={zone} />
                        ))}
                    </List>
                )}
                {!sortedZones && (
                    <div className="mt-4 flex justify-center">
                        <CircularProgress size={25} />
                    </div>
                )}
            </div>
        </>
    );
};
