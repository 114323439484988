import { Alert, Snackbar } from '@mui/material';
import React, { useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';

const IconContainer = styled.div`
    margin-right: 10px;
    flex-shrink: 0;
`;

const MessageBlock = styled.div`
    position: relative;
    width: 266px;
    display: flex;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    align-items: center;
`;

const CloseIconButton = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
`;

const successColor = 'rgba(71 ,200 ,71, 0.95)';
const warningColor = 'rgba(218, 55, 52, 0.95)';

export default function ToastWrapper(props: any) {
    const contentRef = useRef<HTMLDivElement>(null);

    const ToastStyle = {
        whiteSpace: 'pre-line',
        width: '304px',
        background: props.type === 'WARNING' ? warningColor : props.type === 'SUCCESS' ? successColor : '',
        alignItems: 'center',
        color: '#fff',
        transition: '0!important',
        padding: '5px 10px',
    };
    const toastAction = (
        <React.Fragment>
            <CloseIconButton type="button" onClick={props.onClose}>
                <CloseIcon />
            </CloseIconButton>
        </React.Fragment>
    );
    return (
        <Snackbar {...props}>
            <Alert action={toastAction} icon={false} sx={ToastStyle}>
                <MessageBlock ref={contentRef}>
                    <IconContainer>
                        {props.type === 'SUCCESS' ? (
                            <DoneIcon />
                        ) : props.type === 'WARNING' ? (
                            <ErrorIcon />
                        ) : (
                            false
                        )}
                    </IconContainer>
                    {props.message}
                </MessageBlock>
            </Alert>
        </Snackbar>
    );
}
