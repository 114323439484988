import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';
import { Organization } from '../interfaces';
import { getOrganizations } from '../api/getOrganizations';

export interface DevicesState {
    organizations: Organization[] | null;
}

const initialState: DevicesState = {
    organizations: null,
};

export const loadOrganizations = createAsyncThunk('devices/loadOrganizations', async () => {
    return await getOrganizations();
});

export const OrganizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadOrganizations.fulfilled, (state, action) => {
                state.organizations = action.payload;
            })
            .addCase(loadOrganizations.rejected, (state, action) => {
                console.error(action.error);
            });
    },
});

export const selectOrganizations = (state: RootState) => state.organizations.organizations;

export default OrganizationsSlice.reducer;
