import axios from 'axios';
import config from '../config';
import { ActivationStick } from '../interfaces';

export const getActivationSticks = (deviceId?: string): Promise<ActivationStick[]> => {
    let url = `${config.API_URL}/activation-sticks`;

    if (deviceId) {
        url += `?deviceId=${deviceId}`;
    }

    return axios.get(url).then((response: any) => {
        return response.data.data.activationSticks;
    });
};
